<template>
  <div>
    <v-row>
      <v-col cols="5">
        <app-text-search-field
          @filter="showFilter = !showFilter"
          @input="handleSearchInput($event)"
        />
      </v-col>
      <v-col class="text-right d-md-block d-none pb-0">
        <v-btn
          large
          bottom
          color="primary"
          to="/compras/nova-entrada"
          class="mr-5 text-capitalize"
          v-if="$acl.can('purchasesCreateEdit')"
        >
          Novo
        </v-btn>
        <v-btn @click="handleExport()" bottom large outlined color="secondary">
          Exportar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.emitted_at_start"
          type="date"
          label="Data Emissão Início"
          @input="select()"
        />
      </v-col>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.emitted_at_end"
          type="date"
          label="Data Emissão Fim"
          @input="select()"
        />
      </v-col>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.entry_at_start"
          type="date"
          label="Data Entrada Início"
          @input="select()"
        />
      </v-col>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.entry_at_end"
          type="date"
          label="Data Entrada Fim"
          @input="select()"
        />
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col>
        <CompanySelect
          clearable
          @input="setCompanyId($event)"
          :itemValue="(val) => val.id"
        />
      </v-col>
      <v-col cols="3">
        <ProductSearch @input="setProduct($event)" clearable />
      </v-col>
      <v-col cols="3">
        <SupplierSearch @input="setSupplierId($event)" />
      </v-col>
      <v-col cols="3">
        <app-select
          v-model="searchParams.stock_status"
          :items="stockOptions"
          label="Status Estoque"
          @input="select()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <v-chip-group
          @change="select()"
          v-model="searchParams.status"
          column
          multiple
        >
          <v-chip small filter :value="'purchased'" color="primary">
            Compra
          </v-chip>
          <v-chip small filter :value="'draft'"> Rascunho </v-chip>
          <v-chip small filter :value="'canceled'" color="error">
            Cancelado
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total de Compras
              <br />
              <b class="text-h4">{{ $format.decimal(purchases_total) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          disable-sort
          dense
          :headers="headers"
          @click:row="editPurchase($event.id)"
          :items="purchases.data"
          :items-per-page='-1'
        >
          <template v-slot:[`item.company`]="{ item }">
            <CompanyChip :company="item.company" />
          </template>
          <template v-slot:[`item.emited_at`]="{ item }">
            {{ $format.dateBr(item.emited_at) }}
          </template>
          <template v-slot:[`item.entry_at`]="{ item }">
            {{ $format.dateBr(item.entry_at) }}
          </template>
          <template v-slot:[`item.net_total`]="{ item }">
            {{ $format.decimal(item.net_total) }}
          </template>
          <template v-slot:[`item.purchase_total`]="{ item }">
            {{ $format.decimal(item.purchase_total) }}
          </template>
          <template v-slot:[`item.info`]="{ item }">
            <v-icon small color="primary">mdi-cash</v-icon>
            <v-icon
              class="mr-2"
              small
              :color="statusData[item.finance_status].color"
              v-text="statusData[item.finance_status].icon"
            />
            <v-icon small color="primary">mdi-package-variant</v-icon>
            <v-icon
              small
              :color="statusData[item.stock_status].color"
              v-text="statusData[item.stock_status].icon"
            />
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="purchasesStatus[item.status].color" x-small>
              {{ purchasesStatus[item.status].text }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  :disabled="item.status == 'canceled'"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="
                    item.finance_status == 0 &&
                    item.status == 'purchased' &&
                    $acl.can('purchaseStoreBill')
                  "
                  @click.stop="storePurchaseBill(item.id)"
                >
                  <v-list-item-action>
                    <app-icon>point_of_sale</app-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title> Lançar Contas</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click.stop="handleCancelPurchase(item.id)"
                  v-if="
                    item.status == 'purchased' && $acl.can('purchaseCancel')
                  "
                >
                  <v-list-item-action>
                    <app-icon>cancel</app-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Cancelar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click.stop="handleConfirmPurchase(item.id)"
                  v-if="
                    item.status == 'draft' && $acl.can('purchasesCreateEdit')
                  "
                >
                  <v-list-item-action>
                    <app-icon>check</app-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Confirmar Compra</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="
                    item.stock_status == 0 &&
                    item.status == 'purchased' &&
                    $acl.can('purchaseStoreStock')
                  "
                  @click.stop="storePurchaseStockTransaction(item.id)"
                >
                  <v-list-item-action>
                    <v-icon>mdi-dolly</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Lançar Estoque</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <app-pagination @click="select($event)" :data="purchases" />
      </v-col>
    </v-row>
    <PurchaseExportForm ref="PurchaseExportForm" />
  </div>
</template>

<script>
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import PurchaseExportForm from "@/components/purchase/form/PurchaseExportForm.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";

export default {
  components: {
    SupplierSearch,
    CompanySelect,
    CompanyChip,
    PurchaseExportForm,
    ProductSearch,
  },

  data() {
    return {
      purchases: {},
      purchases_total: 0,

      operationsOptionsStatus: {
        product_receipt: { text: "Recebimento de mercadorias" },
        product_receipt_bonus: {
          text: "Recebimento de mercadorias Bonificação",
        },
        repair_return_receipt: { text: "Recebimento Retorno Conserto" },
      },
      operationsOptions: [
        { value: "product_receipt", text: "Recebimento de mercadorias" },
        {
          value: "product_receipt_bonus",
          text: "Recebimento de mercadorias Bonificação",
        },
        {
          value: "repair_return_receipt",
          text: "Recebimento Retorno Conserto",
        },
      ],
      stockOptions: [
        { value: 1, text: "Estoque Lançando" },
        { value: 0, text: "Estoque Pendente" },
        { value: null, text: "Todos" },
      ],

      statusData: {
        1: {
          icon: "mdi-check",
          color: "success",
        },
        0: {
          icon: "mdi-close",
          color: "error",
        },
      },
      purchasesStatus: {
        purchased: {
          text: "Compra",
          color: "primary",
        },
        draft: {
          text: "Rascunho",
          color: "disabled",
        },
        canceled: {
          text: "Cancelado",
          color: "error",
        },
      },

      headers: [
        { text: "ID", value: "id", width: "10%", align: "center" },
        { text: "Nº Nota", value: "number", width: "10%", align: "center" },
        { text: "Empresa", value: "company", width: "10%", align: "center" },
        {
          text: "Fornecedor",
          value: "supplier.name",
          width: "50%",
          align: "center",
        },
        { text: "Emissão	", value: "emited_at", align: "center" },
        { text: "Entrada	", value: "entry_at", align: "center" },
        {
          text: "Total Prod	",
          value: "net_total",
          width: "10%",
          align: "center",
        },
        {
          text: "Total NF	",
          value: "purchase_total",
          width: "10%",
          align: "center",
        },
        {
          text: "Financeiro/Estoque	",
          value: "info",
          align: "center",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          width: "2%",
          align: "center",
        },
        {
          text: "",
          value: "action",
          align: "center",
          width: "1%",
          align: "center",
        },
      ],

      showFilter: null,

      searchParams: {
        status: ["purchased", "draft"],
        emitted_at_start: null,
        emitted_at_end: null,
        entry_at_start: null,
        entry_at_end: null,
      },
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("purchase/purchase", this.searchParams)
        .then((response) => {
          this.$loading.finish();

          this.purchases = response.purchases;
          this.purchases_total = response.purchases_total;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    setSupplierId(supplier) {
      this.searchParams.supplier_id = supplier ? supplier.id : null;
      this.select();
    },

    setCompanyId(company_id) {
      this.searchParams.company_id = company_id;
      this.select();
    },

    editPurchase(purchase_id) {
      if (this.$acl.can("purchasesCreateEdit")) {
        const routeData = this.$router.resolve({
          name: "PurchaseForm",
          params: { id: purchase_id },
        });
        window.open(routeData.href, "_blank");
      }
    },

    storePurchaseBill(id) {
      this.$http
        .store("purchase/purchase-bill", { id: id })
        .then((response) => {
          this.select();
        })
        .catch((error) => {});
    },

    handleCancelPurchase(id) {
      this.$http
        .destroy("purchase/purchase-cancel", id)
        .then((response) => {
          this.select();
        })
        .catch((error) => {});
    },

    handleConfirmPurchase(id) {
      this.$http
        .update("purchase/purchase-confirm", id)
        .then((response) => {
          this.select();
        })
        .catch((error) => {});
    },

    storePurchaseStockTransaction(id) {
      this.$loading.start();

      this.$http
        .update("purchase/purchase-stock-transaction", id)
        .then((response) => {
          this.$loading.finish();

          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    setProduct(product) {
      this.searchParams.product_id = product ? product.id : null;
      this.select();
    },

    handleExport() {
      this.$refs.PurchaseExportForm.open();
    },
  },
};
</script>

<style>
</style>